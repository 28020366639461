

.paymentinfo{
    margin-top: 30px;
    margin-bottom: 20px;
}

.stripecardelement{
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff; /* Added white background */
}