.contactUs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    width: 100vw;
    margin: 0 auto;
    height: 100vh;
  }
  
  .contactUs-inputBox {
    width: 75vw;
    margin-bottom: 20px;
  }

  .contactUs-form {
    background-color: var(--primary-color-trans-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto ;
  }

  
  .contactUs-textarea {
    width: 100%;
    height: 40vh;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    resize: none;
  }
  
  .contactUs-button {
    width: 20vw;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: var(--secondary-color);
    color: #333;
    font-size: 1em;
    cursor: pointer;
    font-weight: 700;
  }
  
  .contactUs-button:hover {
    filter: brightness(85%);
  }

  .contactUs-button:disabled{
    background-color: #ccc;
    cursor: not-allowed;
  }