.plan-selection {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow the plans to wrap to the next line on smaller screens */
  margin-top: 20px;
  margin-bottom: 20px;
}

.plan-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 30%; /* Set a percentage width for desktop */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  margin-bottom: 20px; /* Add margin for spacing between boxes */
}

.plan-box:hover {
  transform: scale(1.05);
}

.plan-box.selected {
  border: 8px solid var(--secondary-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.plan-box h3 {
  margin-bottom: 10px;
}

.plan-box p {
  margin-bottom: 20px;
  font-size: 18px;
}

.plan-box button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

/* Buttons at the bottom */
.subscription-button {
  width: 20vw; /* Adjust to take up full width on mobile */
  padding: 10px;
  font-size: 1em; /* Responsive font size */
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: #333;
  border: none;
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px; /* Add some margin for spacing */
}

.cancel-sub-button {
  width: 20vw; /* Adjust to take up full width on mobile */
  margin-right: 20px; /* Remove margin-right */
  margin-top: 10px; /* Add some margin for spacing */
  font-size: 1em; /* Responsive font size */
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: #333;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

/* Responsive Layout for Mobile */
@media (max-width: 768px) {
  .plan-selection {
    flex-direction: column; /* Stack the plans vertically on mobile */
    align-items: center; /* Center the plans */
  }

  .plan-box {
    width: 90%; /* Take up most of the screen width on mobile */
    margin-bottom: 20px; /* Add spacing between the plan boxes */
  }

  .subscription-button, .cancel-sub-button {
    width: 90%; /* Full width on mobile but with some margin */
    max-width: 300px; /* Limit max width to keep it visually balanced */
  }
}
