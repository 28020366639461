.appheader {
    display: flex;
    justify-content: space-between;  /* This will push the logo to the right */
    align-items: flex-start;         /* This will align items to the top */
    padding: 10px 20px;
    background-color: var(--primary-color);       /* Dark blue background */
    color: #190465;
    height: 60px;                    /* Adjust height as necessary */
    width: 100%;                     /* Ensure header spans full width */
}

.applogo img {
    height: 50px;                    /* Adjust the size of your logo */
}
