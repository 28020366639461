.hamburger-menu {
    margin-right: auto;
  }
  
  .hamburger-button {
    font-size: 30px;
    background: rgba(0, 0, 0, 0); /* Fully transparent */
    border: none;
    cursor: pointer;
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove any default margin */
    color: rgb(255, 255, 255) ;
    height: 35px;
    flex-direction: column;
    justify-content: center; /* Center the bars vertically */
    align-items: center; /* Center the bars horizontally */
  }


  .menu {
    display: none;
    position: absolute;
    top: 40px; /* Position relative to the button */
    left: 0; /* Align with the left edge of the button */
    background-color: var(--primary-color);  /* Dark blue background */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .menu.open {
    display: block;
  }
  
  .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    border-bottom: 1px solid #ddd;
  }
  
  .menu li:last-child {
    border-bottom: none;
  }
  
  .menu a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
  }
  
  .menu a:hover {
    background-color: #f0f0f0;
    color: black;
  }
