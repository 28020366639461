/* General Layout */
.account-page {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.sidebar {
  width: 20vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: var(--primary-color-trans-light);
}

.sidebar button {
  background-color: var(--primary-color-trans);
  color: #fff;
  border: none;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.sidebar button:hover {
  background-color: #555;
}

.content {
  flex: 1;
  padding: 20px;
}

/* Top Navigation for Mobile */
.top-nav {
  display: none;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--primary-color-trans);
  padding: 10px 0;
}

.top-nav button {
  background-color: var(--primary-color-trans);
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
}

.top-nav button:hover {
  background-color: #555;
}

/* Responsive Layout for Mobile */
@media (max-width: 768px) {
  .account-page {
    flex-direction: column;
  }

  .sidebar {
    display: none;
  }

  .top-nav {
    display: flex;
  }

  .content {
    padding-top: 10px;
  }
}

/* Desktop Only Styles */
@media (min-width: 769px) {
  .account-page {
    flex-direction: row;
  }

  .sidebar {
    display: flex;
  }

  .top-nav {
    display: none;
  }

  .content {
    margin-left: 20vh;
  }
}
  
  .account-info-container, .subscription-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--primary-color-trans-light);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .account-info-container, .update-payment-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--primary-color-trans-light);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  
  .account-info, .subscription-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  span {
    color: #333;
    flex-basis: 55%;
    text-align: right;
  }
  
  .update-button {
    width: 20vw;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: #333;
    font-size: 1em;
    cursor: pointer;
    font-weight: 700;
    margin-top: 20px;
  }
  
  .update-button:hover {
    filter: brightness(85%);
  }
  
  .editable-field {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;
    text-align: right;
  }
  
  .editable-field:focus {
    border-color: var(--secondary-color);
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .acctinfolbl{
    font-size: 1em;
  }