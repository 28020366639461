.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100vw;
  margin: 0 auto;
  height: 100vh;
}

.TextVerification {
  background-color: var(--primary-color-trans-light);
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin: 0 auto ;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 75vw; /* Uses viewport width for better responsiveness */
}


textarea {
  width: 100%;
  height: 40vh;
  padding: 10px;
  font-size: 1em; /* Responsive font size */
  margin-bottom: 10px;
  resize: none; /* Disables resizing */
}

.counter {
  display: flex;
  justify-content: flex-end;
  font-size: 1em; /* Responsive font size */
  width: 100%;
}

.counter.error {
  animation: shake 0.5s infinite alternate, flash 1s infinite;
}

@keyframes shake {
  0% { transform: translateX(0); }
  100% { transform: translateX(-5px); }
}

@keyframes flash {
  0% { color: red; }
  50% { color: #ff0000; }
  100% { color: red; }
}

.VerifyButton {
  width: 20vw;
  padding: 10px;
  font-size: 1em; /* Responsive font size */
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: #333;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.VerifyButton:hover {
  filter: brightness(85%);
}

#result {
  margin-top: 20px;
  padding: 10px;
  font-size: 1em; /* Responsive font size */
}

.resulttext{
  font-size: 2em  !important;
  font-weight: bold  !important;
  color: #ddd;
}

.VerifyButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}