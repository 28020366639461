/* UpdatePaymentMethod.css */

.cardlabel {
    color: #ddd;
    font-size: 1em;
    font-weight: bolder;
  }
  
  .stripe-card-element {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff; /* Added white background */
  }
  
  
  .update-payment-button {
    width: 20vw;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: #333;
    font-size: 1em;
    cursor: pointer;
    font-weight: 700;
    margin-top: 20px;
  }
  
  .update-payment-button:hover {
    filter: brightness(85%);
  }
  
  .card-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd; /* Added border */
    background-color: #fff; /* Added white background */
    border-radius: 5px; /* Added border radius */
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;  
}
  
  .card-type {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .card-number {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .card-expiry {
    font-size: 14px;
    color: #666;
  }
  
  .update-payment-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  