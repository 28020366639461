* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px; /* Adds padding for better appearance on smaller screens */
}

.wrapper {
  position: relative;
  width: 90vw; /* Uses viewport width for better responsiveness */
  max-width: 420px; /* Sets a maximum width for larger screens */
  min-height: 42vh;
  background-color: var(--primary-color-trans);
  border-radius: 10px;
  color: #fff;
  margin: 20px 0;
  display: flex;
  transition: height .2s ease;
  overflow: hidden;
}

.wrapper.active {
  height: 690px;
}

.wrapper .form-box {
  width: 100%;
  padding: 40px;
}

.wrapper .form-box.login {
  transition: translate .18s ease;
  position: relative;
  translate: 0;
}

.wrapper.active .form-box.login {
  transition: none;
  translate: -400px;
}

.wrapper .form-box.register {
  position: absolute;
  translate: 400px;
  transition: none;
}

.wrapper.active .form-box.register {
  transition: translate .18s ease;
  position: absolute;
  translate: 0;
}

form h1 {
  font-size: 2em; /* Responsive font size */
  text-align: center;
  color: #fff;
}

form .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, .1);
  border-radius: 40px;
  font-size: 1em; /* Responsive font size */
  color: #fff;
  padding: 20px 45px 20px 20px;
}

.wrapper .input-box {
  margin: 20px 0;
}

.input-box input::placeholder {
  color: #fff;
}

.input-box i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1em; /* Responsive font size */
  color: #fff;
}

form .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 0.75em; /* Responsive font size */
  margin: -15px 0 15px;
}

.remember-forgot label input {
  accent-color: #fff;
}

.remember-forgot {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.remember-forgot label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.remember-forgot input[type='checkbox'] {
  margin-right: 10px;
}

.remember-forgot a {
  color: #fff;
  text-decoration: none;
}

.remember-forgot a:hover {
  text-decoration: underline;
}

.remember-forgot label {
  color: white;
}

form button {
  width: 100%;
  height: 45px;
  background: var(--secondary-color);
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 1em; /* Responsive font size */
  color: #333;
  font-weight: 700;
}

form button:hover {
  filter: brightness(85%);
}

form .register-link {
  font-size: 0.75em; /* Responsive font size */
  text-align: center;
  margin: 20px 0 15px;
}

.register-link p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.register-link p a:hover {
  text-decoration: underline;
}
