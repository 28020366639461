:root {
    --primary-color: #01264c;       /* Dark blue background */
    --primary-color-trans: rgba(1, 38, 76, 0.9); /* 50% transparent */
    --primary-color-trans-light: rgba(1, 38, 76, 0.6);
    --secondary-color : #eb9400 /* orange */
    ;
}

body {
  background-image: url('../public/background.png');
  background-size: cover; /* Ensures the background covers the entire element */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
  color: #ddd;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.message {
  color: #ddd;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-container{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}