.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .modal h2 {
    margin-top: 0;
    color: var(--primary-color);
  }
  
  .modal p {
    color: #333;
    font-size: 1em;
    margin: 20px 0;
  }
  
  .modal button {
    width: 100px;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
  }
  
  .modal button:first-of-type {
    background-color: var(--secondary-color);
    color: #333;
  }
  
  .modal button:last-of-type {
    background-color: var(--primary-color);
    color: #fff;
  }
  